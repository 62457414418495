import { createRouter, createWebHistory } from 'vue-router'
import AssessmentMarketing from '../views/AssessmentMarketing.vue'
import Assessment from '../views/Assessment.vue'
import WebHome from '../views/WebHome'
import Pricing from '../views/Pricing.vue'
import About from '../views/About.vue'
import Legal from '../views/Legal.vue'
import Contact from '../views/Contact.vue'
import People from '../views/People.vue'
import Privacy from '../views/Privacy.vue'

const routes = [
  {
    path: '/',
    name: 'WebHome',
    component: WebHome
  },
  {
    path: '/AssessmentMarketing',
    name: 'AssessmentMarketing',
    component: AssessmentMarketing
  },
  {
    path: '/assessment',
    name: 'Assessment',
    component: Assessment
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/assessment',
    name: 'Assessment',
    component: Assessment
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  {
    path: '/people-and-hr',
    name: 'People',
    component: People
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
