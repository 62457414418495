<template>
  <div class="my-12 grid  grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">
    <div v-for="company in companyList" :key="company.name" class="flex flex-col items-center mb-4">
      <Icon :icon="company.icon" class="w-8 h-8 company-logo text-gray-300 mb-2" />
      <span class="text-sm text-gray-300">{{ company.name }}</span>
    </div>
  </div>
</template>
  
  <script>
  import { Icon } from "@iconify/vue";
  
  export default {
    components: { Icon },
    data() {
      return {
        companyList: [
        { name: "Apple Inc.", icon: "mdi:apple" },
        { name: "Google LLC", icon: "mdi:google" },
        { name: "Facebook, Inc.", icon: "mdi:facebook" },
        { name: "Twitter, Inc.", icon: "mdi:twitter" },
        { name: "LinkedIn Corporation", icon: "mdi:linkedin" },
        { name: "Microsoft Corporation", icon: "mdi:microsoft" },
        { name: "Amazon.com, Inc.", icon: "mdi:amazon" },
    
        ],
      };
    },
  };
  </script>
  

  