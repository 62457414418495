<template>
  <NavHeaderVue/>
  <section>
    <h2
    class="mx-8 md:mx-24 lg:mx-28 my-8 md:my-12 text-center text-zinc-750 text-4xl md:text-5xl leading-tight tracking-wide font-semibold"
  >
Contracts Made Simple
  </h2>
  <div class="m-8">
    <img src="@/assets/legal.svg" alt="">
  </div>
  <!-- <p class="m-8 text-center md:text-sm text-xs text-zinc-800  tracking-wide my-4">Vega represents an innovative legal tech advancement that harnesses the power of automation and AI to enhance collaboration and efficiency for teams and businesses across diverse sectors. Our user-friendly platform simplifies various legal tasks, delivering a seamless end-to-end experience. This includes automated contract generation, real-time collaboration, integrated eSigning, streamlined contract management, and data-driven insights. Embracing an all-in-one strategy, our solution expedites legal processes, eradicates the necessity for multiple tools, and enables teams to engage confidently with legal matters while safeguarding compliance. In essence, Vega saves businesses valuable time, resources, and costs.</p> -->
  <h2
    class="mx-8 md:mx-24 lg:mx-28  my-8 md:my-12 text-center text-zinc-750 text-3xl md:text-4xl leading-tight tracking-wide font-semibold"
  >
    Our Story 
  </h2>
  <div class="flex justify-between flex-col md:flex-row space-x-4 m-8">
    <img class="md:w-[50%]" src="@/assets/story.svg"  alt="">
    <p class=" text-sm  md:text-sm text-zinc-800  lg:leading-8 md:leading-7 tracking-wide my-4">
      At My Pocket Counsel, we're on a mission to prove that legal operations don't have to be a snooze-fest or a roadblock for businesses. We rejected the idea that contracting should be a complex process full of jargon-induced headaches. Instead, we believe it should be as exciting as the next big business move.
  
      <br/>
      Our philosophy is simple: legal can be dynamic, straightforward, and downright fun. So, we rolled up our sleeves, donned our capes, and set out to give businesses a legal ally that’s as vibrant and agile as they are.
      <br/>
      My Pocket Counsel is not your average legal tech superhero – it's the disruptor of dull, the conqueror of complexities, and the liberator of legal tedium. We're here to redefine the way businesses work with legal, bringing speed, style, and a touch of swagger to the world of contracts, compliance, and everything in between.
      <!-- Vega was founded in 2021 by a team of legal and tech professionals who recognized the need for a comprehensive legal platform that could simplify and expedite legal processes. Our mission is to empower businesses with the tools they need to navigate the complexities of legal matters with ease. We are committed to delivering a solution that is user-friendly, efficient, and cost-effective, enabling businesses to focus on growth and innovation. Vega is designed to be a game-changer in the legal tech industry, providing businesses with the confidence and capability to engage with legal matters effectively and securely. -->

</p>
  </div>  
</section>
  <!-- <Clients/> -->
  <Footer/>
 </template>
 <script>

import NavHeaderVue from '@/components/layout/NavHeader.vue';
import Clients from '@/components/Dashboard/Clients.vue';
import Features from '@/components/Dashboard/Features.vue';
import Footer from '@/components/layout/Footer.vue';


 export default {
   components: {
     NavHeaderVue, Clients, Features, Footer, 
   },
   setup() {
 
     return {
    
     }
   }
 };
 </script>
 