<template>

<div class="p">
    <header>
        <h1>Vega Privacy Policy</h1>
    </header>
    <section>
        <h2>Security & Compliance</h2>
        <p>What happens in Vega, stays in Vega</p>
        <p>Security and compliance are top priorities for us at Vega because it goes to the core of your experience of our product. We are committed to securing your application’s data, eliminating systems vulnerability, and ensuring continuity of service.</p>
    </section>
    <section>
        <h2>Compliance and Certification</h2>
        <h3>GDPR</h3>
        <p>We are compliant with the General Data Protection Regulation (GDPR), that dictates that entities dealing with any European Union data through a vendor, need a contractual agreement in place with each vendor so the EU knows they’re only doing business with companies that fully comply with the GDPR.</p>
        <p>Vega has provided the same privacy benefits and standards to all our users and does not limit this to European users only. All customer data (including marketing data) is treated in a way that conforms with GDPR. Vega stores as little details as possible about you and your project to ensure the security of your data. However, in the event of you requiring support from the Vega support team specifically, we’ll use very basic information to adequately assist you to troubleshoot your query. This may include the email address and project ID associated with the project query. The content you add using Vega is stored in Firebase and not Vega itself.</p>
        <p>All third party vendors that Vega integrates with are GDPR compliant, namely: Google Cloud Platform, and MailChimp.</p>
        <h3>Permanent Data Deletion</h3>
        <p>All users have the right to have their data deleted permanently. All you need to do is email our help contact and we will have your data and account deleted permanently.</p>
        <h3>ISO and SOC compliance</h3>
        <p>Vega data is hosted on Google Cloud Platform, which encrypts all data at rest by default, in compliance with the Privacy Rule within HIPAA Title II.</p>
        <p>All Firebase services have successfully completed the ISO 27001 and SOC 1, SOC 2, and SOC 3 evaluation process, and some have also completed the ISO 27017 and ISO 27018 certification process. Read more about the individual services here.</p>
        <h3>PCI DSS</h3>
        <p>Vega’s payment and card information is handled by Paystack which has been audited by an independent PCI Qualified Security Assessor and is certified as a PCI Level 1 Service Provider, the most stringent level of certification available in the payments industry.</p>
        <p>Credit card data never touches any of the Vega servers, making it compliant with Payment Card Industry Data Security Standards (PCI DSS). Vega also uses Paystack provided UI elements for capturing credit card information within the application’s frontend, which communicates directly with Paystack’s servers.</p>
    </section>
    <section>
        <h2>Infrastructure and Network Security</h2>
        <h3>Physical Access Control</h3>
        <p>Vega is a cloud-native service and is hosted on Firebase and the greater Google Cloud Platform. We do not have data centres of our own. Google data centres feature a layered security model, including extensive safeguards such as:</p>
        <ul>
            <li>Custom-designed electronic access cards</li>
            <li>Alarms</li>
            <li>Vehicle access barriers</li>
            <li>Perimeter fencing</li>
            <li>Metal detectors</li>
            <li>Biometrics</li>
        </ul>
        <p>Vega employees do not have physical access to Google data centres, servers, network equipment, or storage.</p>
        <h3>Logical Access Control</h3>
        <p>Vega is the assigned administrator of its infrastructure on Google Cloud Platform, and only designated authorized Vega engineering team members have access to configure the infrastructure based on access roles.</p>
    </section>
    <section>
        <h2>Intrusion Detection and Prevention</h2>
        <p>Unusual network patterns or suspicious behaviour are among Vega’s biggest concerns for infrastructure hosting and management. Google Cloud Platform’s intrusion detection and prevention systems (IDS/IPS) rely on both signature-based security and algorithm-based security to identify traffic patterns that are similar to known attack methods.</p>
        <p>IDS/IPS involves tightly controlling the size and make-up of the attack surface, employing intelligent detection controls at data entry points, and developing and deploying technologies that automatically remedy dangerous situations, as well as preventing known threats from accessing the system in the first place.</p>
        <p>Vega does not provide direct access to security event forensics but does provide access to the engineering team during and after any unscheduled downtime.</p>
    </section>
    <section>
        <h2>Business Continuity</h2>
        <p>Vega keeps daily encrypted backups of user profile data (the minimum data required to run Vega) on the Google Cloud Platform. While never expected, in the case of production data loss (i.e., primary data stores lost), we will restore organizational data from these backups. This organizational data does not include a user’s Firebase project data.</p>
        <p>It is important to note that all project-specific data is stored within your own Firebase project linked to Vega - within your Firebase Realtime Database, as well as your Cloud Storage Bucket and Firebase Authentication service. Vega does not automatically back up your own data.</p>
        <p>You are free to comply with additional backup requirements beyond what Vega provides by using the Backup & Restore module or by using Firebase’s backup functionality.</p>
        <p>In the event that Vega’s application is unavailable, your project’s availability is isolated and is subject to your own security and redundancy setup. This also means that you are not tied into Vega and always remain in control of your own data.</p>
    </section>
    <section>
        <h2>Disaster Recovery</h2>
        <p>In the event of a region-wide outage, Vega will endeavour to bring up a duplicate environment within a reasonable amount of time.</p>
    </section>
    <section>
        <h2>Data Security and Privacy</h2>
        <h3>Data Encryption</h3>
        <h4>Encryption at rest</h4>
        <p>All data in Vega’s servers is automatically encrypted at rest. Google Cloud Platform stores and manages data cryptography keys in its redundant and globally distributed Key Management Service. So, in the unlikely event of an intruder gaining access to any of the physical storage devices, the Vega data contained therein would still be impossible to decrypt without the keys, rendering the information a useless jumble of random characters.</p>
        <p>Encryption at rest also enables continuity measures like backup and infrastructure management without compromising data security and privacy.</p>
        <h4>Encryption in transit</h4>
        <p>Vega exclusively sends data over HTTPS transport layer security (TLS) encrypted connections for additional security as data transits to and from the application. This includes data sent through our Content Delivery Network (CDN) and services within GCP, as well as integration with Paystack for all billing related transactions.</p>
        <h3>Data Removal</h3>
        <p>All customer data stored on Vega servers is eradicated upon a customer’s termination of service. The only exception is non-personally identifiable data that is retained to ensure that the same Firebase project cannot qualify for a free trial numerous times.</p>
    </section>
    <section>
        <h2>Application Security</h2>
        <h3>API Authentication</h3>
        <p>Vega’s API uses Firebase auth tokens for authentication. Authentication tokens are passed using the auth header and are used to authenticate a user account with the API.</p>
        <p>The Vega API has Cross-Origin Resource Sharing (CORS) enabled only for a small subset of whitelisted domains and is not accessible publicly otherwise.</p>
        <h3>Audit Controls</h3>
        <p>Secure Application Development (Application Development Lifecycle)</p>
        <p>Vega practices continuous delivery, which means all code changes are committed, tested, shipped, and iterated on in a rapid sequence. A continuous delivery methodology, complemented by pull request, continuous integration (CI), and automated error tracking, significantly decreases the likelihood of a security issue and improves the response time for the effective eradication of bugs and vulnerabilities.</p>
    </section>

    <section>
        <h2>Data Access and Sharing with Google</h2>
    
        <p>When you sign in to our application using Google Sign-In (OAuth), we may collect certain information about you from Google, which may include:</p>
        <ul>
            <li>Basic user information: This typically includes your name, email address, and profile picture. The specific information collected will depend on the permissions you grant when authorizing our application through Google Sign-In.</li>
            <li>Unique identifier: Google may also provide us with a unique identifier associated with your Google account.</li>
        </ul>

        <p>We use this information to:</p>
        <ul>
            <li>Authenticate your account: We verify your identity and allow you to access our application using your Google credentials.</li>
            <li>Provide core functionalities: Depending on the features of our application, we may use your information to personalize your experience, deliver content relevant to you, or perform other actions necessary for the application's functionality.</li>
        </ul>
       <h3>Using Google Drive API</h3>
        <p>Our application utilizes Google OAuth to connect with your Google account. This allows you to grant us permission to access specific parts of your Google Drive. We will<span style="font-weight: bolder;">never</span>  request access to your entire Drive or any data beyond what you explicitly authorize.</p>
    
        <h3>Data Accessed Through Google Drive</h3>
        <p>The specific data we access from your Drive depends on the functionalities you choose within our application. We may request access to.</p>
        <ul>
            <li>File names and folder structures: This helps us understand the organization of your Drive and display relevant files within the application.</li>
            <li>File content: This might be necessary if our application allows you to directly work with documents or files stored in your Drive.</li>
        </ul>
<br/>
        <h3>Data Use And Storage</h3>
        <p>We will only use the data accessed from your Drive for the purposes described within this Privacy Policy and for fulfilling the functionalities of our application. We will <span style="font-weight: bolder;">not</span> share this data with any third-party except as required by law or to provide the services you request.</p>
       
        <h3>User Control and Revocation</h3>
        <p>You have complete control over the data we access from your Drive. You can:</p>
        <ul>
            <li>Review the permissions granted to our application at any time through your Google Account settings</li>
            <li>Revoke our access entirely through your Google Account settings.</li>
        </ul>
        <p>This will prevent our application from accessing any further data from your Drive.</p>
    
        <h3>Security</h3>
        <p>We maintain industry-standard security measures to protect the data we access from your Drive. However, you are responsible for maintaining the security of your Google Account credentials.</p>
    
        <h3>Data Sharing with Google</h3>
        <p>We do not share any personally identifiable information (PII) with Google beyond what is necessary to facilitate the Google Sign-In process and operate our application.</p>
    
    </section>

    <section>
        <h2>Corporate Security</h2>
        <h3>Risk Management</h3>
        <p>All Vega product changes must go through code review, CI, and a build pipeline to reach production servers. Only designated employees on Vega’s engineering team have deployment access to production servers.</p>
        <p>We perform testing and risk management on all systems and applications on a regular and ongoing basis. New methods are developed, reviewed, and deployed to production via a pull request and an internal review.</p>
        <h3>Disclosure Policy</h3>
        <p>Vega notifies customers of any data breaches as soon as possible via email, followed by multiple periodic updates throughout each day addressing progress and impact.</p>
        <p>Vega plans include a dedicated customer success manager who holds responsibility for customer communication, as well as regular check-ins and escalations.</p>
        <h3>Vulnerability Disclosure</h3>
        <p>If you would like to report a vulnerability or have any security concerns with any of the Vega services, please contact hello@mypocketcounsel.co.uk We take all disclosures seriously. We will verify each vulnerability before taking the necessary steps to fix it. Once verified, we send status updates as issues are resolved. Please avoid announcing any vulnerabilities publicly to enable us to verify the vulnerability and apply a fix before potential exploitation.</p>
    </section>
</div>
   
  </template>
     <script>
  import NavHeaderVue from "@/components/layout/NavHeader.vue";
  import Clients from "@/components/Dashboard/Clients.vue";
  import Features from "@/components/Dashboard/Features.vue";
  import ContactUs from "@/components/ContactUs/ContactUs.vue";
  import Footer from "@/components/layout/Footer.vue";
  import ContactInfo from "@/components/ContactUs/ContactInfo.vue";
  
  import { ref } from 'vue';
  
  export default {
    components: {
      NavHeaderVue,
      Clients,
      Features,
      Footer,
      ContactUs,
      ContactInfo
    },
    setup() {
      const yellowIcon = ref("mr-4 mt-0.5 text-orange-500")
      return {yellowIcon};
    },
  };
  </script>
  <style scoped>
  .p {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

header {
    background: #333;
    color: #fff;
    padding: 10px 0;
    text-align: center;
}

h1 {
    margin: 0;
    font-size: 24px;
}

section {
    padding: 15px;
    margin: 15px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #333;
    border-bottom: 2px solid #f4f4f4;
    padding-bottom: 5px;
}

h3, h4 {
    color: #666;
}

ul {
    list-style-type: disc;
    margin-left: 20px;
}

p {
    margin: 10px 0;
}
</style>
  
  