<template>
  <section class=" mx-12">
    <h2 class="text-sky-950 text-2xl md:text-4xl font-semibold md:leading-[50px]">
      FAQs
    </h2>
    <div v-for="(faq, index) in faqs" :key="index" class="mb-4 w-full">
      <div @click="toggleOpen(index)" class="flex items-center justify-between cursor-pointer my-4 text-zinc-800 ">
  
        <p class="md:text-base text-sm font-semibold">{{ faq.question }}</p>
        <span class="mr-2 text-lg">{{ isOpen(index) ? "-" : "+" }}</span>
      </div>
      <div v-if="isOpen(index)" class="mt-2">
        <p class="text-xs md:text-sm leading-[25px] md:leading-[35px]">{{ faq.answer }}</p>
      </div>
      <hr class="my-2 border-zinc-300">
    </div>
  </section>
</template>
  
  <script>
export default {
  data() {
    return {
      openIndex: null,
      faqs: [
        {
          question: "How long does it take to resolve an issue?",
          answer:
            "We've got your back – speedy and snappy! We aim to resolve your issues ASAP, but please allow a day or 2 in some cases.",
        },
        {
          question: "In what format will I get my contract?",
          answer:
            "Your documents are generated instantly as a PDF - ready to sign. Need a little flex with the format? No sweat – easily convert it to .docx and dance your way through the paperwork hassle-free!.",
        },
        {
          question: "How do I get my custom contracts onboarded on my platform?",
          answer:
            "Getting your own custom contracts on board is a breeze! Just shoot us a message with your specific templates, and our magic-makers will whip up a tailor-made masterpiece for you. It's like ordering your favorite pizza – but with legal docs!",
        }
      ],
    };
  },
  methods: {
    toggleOpen(index) {
      this.openIndex = this.openIndex === index ? null : index;
    },
    isOpen(index) {
      return this.openIndex === index;
    },
  },
};
</script>
  
