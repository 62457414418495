<template>
    <div class="mt-12">
        <h2 class="text-sky-950 text-2xl md:text-3xl font-semibold md:leading-[60px] leading-[30px]">{{title}}</h2>
    <p class=" text-zinc-800 md:text-base text-sm md:font-normal font-light md:leading-[35px] leading-[25px]">{{description}}</p>
    </div>
   
</template>
<script>
export default {
    props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    }
}
}

</script>