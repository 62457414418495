<template>
    <NavHeaderVue/>
    <PeopleInfo/>
    <!-- <Clients/> -->
    <Footer/>
   </template>
   <script>
 
 import NavHeaderVue from '@/components/layout/NavHeader.vue';

 import Clients from '@/components/Dashboard/Clients.vue';
 import Features from '@/components/Dashboard/Features.vue';
 import Footer from '@/components/layout/Footer.vue';
 import PeopleInfo from '@/components/Dashboard/PeopleInfo.vue';

 
   export default {
     components: {
       NavHeaderVue, Clients, Features, Footer, PeopleInfo
     },
     setup() {
   
       return {
      
       }
     }
   };
   </script>
   