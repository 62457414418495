<template>
  <header class="text-primary p-4 flex items-center justify-between mx-14">
    <div class="flex items-center space-x-4">
      <img src="@/assets/vega.svg" alt="Company Logo" class="h-12 w-12" />
      <span class="text-[32px] font-medium font-inter">Vega</span>
    </div>
    <div class="hidden md:flex items-center space-x-4  text-right">
        <span
          class=" text-base  ml-1 tracking-wide"
          >Want a Demo? </span
        >
        <a href="https://calendly.com/mypocketcounsel/30min" target="_blank" rel="noopener noreferrer"><span
          class="text-amber-400 text-base underline "
          >Book now<br
        /></span></a>
    </div>
  
  </header>
  <div class="w-100% border border-zinc-300 border-opacity-60"></div>
</template>
  
  <script>

  export default {
  name: 'Header',
  setup() {
 
    return {

    };
  },
};
</script>
  
 
  