<template>
  <button :class="buttonClasses" @click="handleClick">{{ buttonText }}</button>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    clickHandler: {
      type: Function,
      default: () => {},
    },
    backgroundColor: {
      type: String,
      default: "bg-sky-950",
    },
    textColor: {
      type: String,
      default: "text-white",
    },
    borderColor: {
      type: String,
      default: "border-sky-900",
    },
  },
  setup(props) {
    const handleClick = () => {
      // Call the dynamically passed function
      props.clickHandler();
    };

    return {
      handleClick,
      buttonClasses: [
        "md:px-4",
        "md:py-2",
        "px-3",
        "py-1",
        "md:text-sm",
        "text-xs",
        props.backgroundColor,
        "rounded-[50px]",
        "border",
        props.borderColor,
        props.textColor,
        "justify-center",
        "items-center",
        "inline-flex",
        "cursor-pointer",
      ],
    };
  },
};
</script>
