
<template>
  <div class="bg-neutral-50 w-full text-base p-6 pb-4 tracking-wide shadow-md">

    <p class="text-zinc-750 font-medium md:leading-7 my-4 w-full sm:w-[50%]">
      Fill in the form and we’ll get back to you within 12 hours
    </p>
    <form class="space-y-8 text-gray-400 text-sm">
      <div :class="inputBody">
        <label for="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          :class="input"
          v-model="formData.name"
        />
      </div>

      <div :class="inputBody">
        <label for="email">Email Address</label>
        <input
          type="email"
          id="email"
          name="email"
          :class="input"
          v-model="formData.email"
        />
      </div>

      <div class="mt-8 border-[2px] border-gray-200">
        <label for="message" class="m-2">Write message here...</label>
        <textarea
          id="message"
          name="message"
          rows="4"
          :class="input"
          v-model="formData.message"
        ></textarea>
      </div>
   <!-- Validation error messages -->
   <div v-show="formError" class="text-red-500">
      {{ formError }}
    </div>
      <button
        type="submit"
        class="bg-sky-950 text-white py-2 px-8"
        @click.prevent="submitForm()"
      >
        Submit
      </button>
    </form>
  </div>
   <!-- Thank you modal -->

   <div v-if="showThankYouModal === true" class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-8 rounded-md text-center">
      <p class="text-xl font-semibold text-sky-950">Thank You!</p>
      <p>You will get a response from us soon.</p>
      <button @click="resetForm" class="mt-4 bg-sky-950 text-white px-4 py-2 rounded-md">Close</button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { projectFunctions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

export default {
  setup() {
    const input = ref("w-full bg-inherit text-sky-900 text-sm p-2 border-none focus:outline-none focus:border-none focus:ring-0");
    const inputBody = ref("border-b-[1px] border-gray-300");
    let showThankYouModal = ref(false);
    const formError = ref("");
    const formData = ref({
      name: "",
      email: "",
      message: "",
    });
    const validateForm = () => {
      formError.value = ""; // Reset error message

      if (!formData.value.name || !formData.value.email || !formData.value.message) {
        formError.value = "Please fill in all fields.";
        return false;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.value.email)) {
        formError.value = "Please enter a valid email address.";
        return false;
      }

      return true;
    };
    const send = httpsCallable(projectFunctions, "equiryEmail1");
    const resetForm = () => {
      // Clear form data and close the thank you modal
      formData.value = { name: "", email: "", message: "" };
      showThankYouModal.value = false; 
  
    };
    const submitForm = async () => {
      if (validateForm()) {
        console.log("Before sending email", formData.value);
        try {
          console.log("Sending email", send);
          await send({
            name: formData.value.name,
            email: formData.value.email,
            message: formData.value.message,
          });
          console.log("Email sent successfully");
// Clear form data and show thank you modal

showThankYouModal.value = true; 
console.log('showThankYouModal', showThankYouModal);
     
          
        } catch (error) {
          console.error(error);
        }
      }
    };





    return { input, inputBody, formData, formError, submitForm, resetForm, showThankYouModal, validateForm };
  },
};
</script>

<style scoped>
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
</style>
