<template>
  <nav class="p-4 text-neutral-700 text-sm">
    <div class="flex items-center justify-between">
      <!-- Menu Icon for smaller screens -->
      <div class="md:hidden cursor-pointer" @click="toggleMobileMenu">
        <Icon icon="feather:menu" class="w-6 h-6" />
      </div>

      <!-- Navigation for larger screens -->
      <div class="hidden md:flex space-x-8">
        <!-- Display for nav-items without items -->
        <router-link
          v-for="navItem in filteredNavigation.withoutItems"
          :key="navItem.route"
          :to="navItem.route"
          :class="{ 'text-sky-950': activeNav === navItem.name }"
          @click="setActiveNav(navItem.name)"
        >
          {{ navItem.name }}
        </router-link>

        <!-- Display for nav-items with items -->
        <div
          v-for="navItem in filteredNavigation.withItems"
          :key="navItem"
          class="relative"
          @click="toggleDropdown(navItem.name)"
        >
          <div
            class="cursor-pointer flex items-center"
            :class="{ 'text-sky-950': activeDropdown === navItem.name }"
            style="z-index: 1;"
          >
            {{ navItem.name }}
            <Icon icon="ep:arrow-down" class="ml-1 w-4 h-2" />
          </div>
          <div
            v-if="activeDropdown === navItem.name"
            class="bg-white absolute mt-2 px-4 py-2 w-[200px] rounded shadow-lg flex flex-col space-y-2 text-neutral-700 text-sm"
          >
            <router-link
              v-for="(item, index) in navItem.items"
              :key="index"
              :to="item.route"
              class="cursor-pointer mt-4 hover:opacity-80"
              @click="setActiveNav(item.title)"
            >
              {{ item.title }}
            </router-link>
          </div>
        </div>
      </div>


<!-- Mobile Menu for smaller screens -->
<div v-if="showMobileMenu" class="md:hidden fixed text-base inset-0 bg-sky-50 bg-opacity-95 z-50 pl-8 pt-8 text-sky-950 font-bold">
  <div class="flex flex-col p-4 space-y-6">
    <span v-for="navItem in navigation" :key="navItem.route">
      <div class="flex items-center space-x-2">
        <div 
          class="cursor-pointer hover:text-sky-950 "
          @click="setActiveNav(navItem.name)"
        > <span v-if="!navItem.items" @click="$router.push(navItem.route)">
  {{ navItem.name }}
</span>
        <span v-else> {{ navItem.name }} </span> 
        </div>
        <Icon v-if="navItem.items" @click="toggleDropdown(navItem.name)" icon="ep:arrow-down" class="ml-1 mt-1 w-4 h-4 text-black cursor-pointer" />
      </div>
      <div v-if="navItem.items && activeDropdown === navItem.name" class="ml-8">
        <router-link
          v-for="subItem in navItem.items"
          :key="subItem.route"
          :to="subItem.route"
          class="flex cursor-pointer font-semibold text-sm ml-12 mt-2  "
          @click="setActiveNav(subItem.title)"
        >
          {{ subItem.title }}
        </router-link>
      </div>
    </span>
  </div>
</div>


    </div>
  </nav>
</template>
  
  <script>
import { ref, computed } from "vue";
import { Icon } from "@iconify/vue";

export default {
  components: { Icon },
  setup() {
    const activeDropdown = ref(null);
    const activeNav = ref(null);

    const toggleDropdown = (dropdown) => {
      activeDropdown.value =
        activeDropdown.value === dropdown ? null : dropdown;
    };
    const filteredNavigation = computed(() => {
      return {
        withItems: navigation.filter(
          (navItem) => navItem.items && navItem.items.length > 0
        ),
        withoutItems: navigation.filter(
          (navItem) => !navItem.items || navItem.items.length === 0
        ),
      };
    });
    const setActiveNav = (nav) => {
      activeNav.value = nav;
      activeDropdown.value = null;
    };

    const navigation = [
      {
        name: "Home",
        route: "/",
      },
      {
        name: "Solutions",
        route: "/solutions",
        items: [
          { title: "People and HR", route: "/people-and-hr" },
          { title: "Legal", route: "/legal" },
          // { title: "Book Demo", route: "/book-demo" },
        ],
      },
      {
        name: "Company",
        route: "/company",
        items: [
          { title: "About Us", route: "/about-us" },
          { title: "Contact Us", route: "/contact-us" },
          // { title: "Book Demo", route: "/book-demo" },
        ],
      },
      // {
      //   name: "Pricing",
      //   route: "/pricing",
      // },
      {
        name: "Scorecard",
        route: "/assessment",
      },
    ];
    const showMobileMenu = ref(false);

    const toggleMobileMenu = () => {
      showMobileMenu.value = !showMobileMenu.value;
    };
    return {
      activeDropdown,
      activeNav,
      toggleDropdown,
      setActiveNav,
      navigation,
      filteredNavigation,
      showMobileMenu,
      toggleMobileMenu,
    };
  },
};
</script>
  