<template>
  <NavHeaderVue/>
  <LegalInfo/>
  <!-- <Clients/> -->
  <Footer/>
 </template>
 <script>

import NavHeaderVue from '@/components/layout/NavHeader.vue';

import Clients from '@/components/Dashboard/Clients.vue';
import Features from '@/components/Dashboard/Features.vue';
import Footer from '@/components/layout/Footer.vue';
import LegalInfo from '@/components/Dashboard/LegalInfo.vue';


 export default {
   components: {
     NavHeaderVue, Clients, Features, Footer, LegalInfo
   },
   setup() {
 
     return {
    
     }
   }
 };
 </script>
 