<template>
   <div  class=" justify-center items-center grid" style="justify-items:center ;">
        <div class="col-start-1 row-start-1" style="width: 35%; margin-top: 0.5rem;" >
            <img src="https://firebasestorage.googleapis.com/v0/b/mpcwebsite2.appspot.com/o/googlepng.png?alt=media&token=2758a6bb-62ed-4e95-ae07-2732644ca9fe"  
        />
        </div>
        <div class="col-start-1 " style="text-align: center;">
            <span class="text-md text-gray-300">Backed by Google</span>
        </div>
      </div>
  
  </template>
    
    <script>
    
    
    export default {
     
      data() {
        return {
  
        };
      },
    };
    </script>
    
  
    