<template>
  <div
    class="bg-cover bg-center relative p-8"
    :style="`background-image: url(${require('@/assets/bg.png')})`"
  >
    <section class="w-full md:w-[60%] mx-auto">
      <h2
        class="my-4 text-center text-zinc-750 text-4xl md:text-5xl tracking-wide font-semibold"
        :style="'line-height : 1.5'"
      >
        Scale your
        <span class="text-yellow-500">legal team</span> accross your business
      </h2>

      <p class="text-center text-zinc-800 md:leading-7 tracking-wide my-4">
        Let's handle the menial work so you can focus on what matters.
      </p>
      <div
        class="flex flex-row items-center my-8 justify-center space-x-4 md:space-y-0 space-x-0 md:space-x-4"
      >
      <a href="https://app.mypocketcounsel.com/login" target="_blank" rel="noopener noreferrer">
            <Button buttonText="Request a Demo" :clickHandler="handleClick" />
          </a>
      </div>
    </section>
  </div>
  <section class="mx-12">
    <section :class="sectionClasses">
      <FeatContent
        class="w-full lg:w-2/5"
        :title="'Your all-in-one legal platform'"
        :description="`Streamline and digitalise the entire contract lifecycle from creation to renewal, and securely store, organise, and manage all your legal tasks in one convenient location. Gain a comprehensive overview and stay on top of legal progress with intuitive dashboards.`"
      />
      <div :class="imageContainer">
        <img src="@/assets/legal1.svg" alt="" />
      </div>
    </section>
    <section :class="sectionClasses" class="flex flex-col-reverse">
      <div :class="imageContainer">
        <img src="@/assets/legal2.svg" alt="" />
      </div>
      <FeatContent
        class="w-full lg:w-2/5"
        :title="'Unlock the full potential of legal'"
        :description="`Let technology empower your organisation with legal confidence through our user-friendly and automated contract creation tool. Save valuable time for your legal team to focus on what truly matters.`"
      />
    </section>
    <section :class="sectionClasses">
      <FeatContent
        class="w-full lg:w-2/5"
        :title="'Reduce legal lead time'"
        :description="`Work smarter together by assigning tasks, auto cration and automatic storage. Reduce lead time by handling everything legal in the same platform.`"
      />
      <div :class="imageContainer">
        <img src="@/assets/legal3.svg" alt="" />
      </div>
    </section>
  </section>
</template>
<script>
import { Icon } from "@iconify/vue";
import Button from "../UI/Button.vue";
import FeatContent from "./FeatContent.vue";
import { ref } from "vue";

export default {
  components: { Icon, Button, FeatContent },
  setup() {
    const sectionClasses = ref(
      "  lg:flex lg:flex-row lg:justify-between lg:item-center lg:justify-center"
    );
    const imageContainer = ref("w-full md:w-80% md:block lg:w-1/2 py-8");
    return { sectionClasses, imageContainer };
  },
};
</script>
