<template>
  <div class="my-12 mx-12 md:mx-24 flex justify-between text-primary">
    <section>
      <div class="flex items-center space-x-2 mb-4">
        <img src="@/assets/vega.svg" alt="Company Logo" class="h-8 w-8" />
        <span class="text-xl font-medium font-inter ">Vega</span>
      </div>
      <p :class="linkStyle">
        Vega utilizes cutting-edge technology to <br> automate your contractual
        needs.
      </p>
      <div class="flex items-center space-x-2">
        <Icon icon="twemoji:twitter" class="h-6 w-6 text-blue-500" />
        <Icon icon="twemoji:instagram" class="h-6 w-6 text-pink-500" />
      </div>
    </section>
    <section class="flex flex-col space-y-1">
      <h3 :class="headerStyle">Quick Links</h3>
      <router-link :class="linkStyle" to="/people-and-hr">Solutions</router-link>
      <router-link :class="linkStyle" to="/about-us">About Us</router-link>
      <!-- <router-link :class="linkStyle" to="/pricing">Pricing</router-link> -->
      <router-link :class="linkStyle" to="/contact-us">Contact Us</router-link>
    </section>
    <section class="md:flex md:flex-col md:space-y-1 hidden md:block">
      <h3 :class="headerStyle">Legal</h3>
      <a :class="linkStyle" target="_blank" href="https://firebasestorage.googleapis.com/v0/b/mpcwebsite2.appspot.com/o/MPC%20Company%20Files%2FTerms%20of%20service.pdf?alt=media&token=389a4fdf-ef28-4b4f-8881-a7c213d25c6f">Terms of Use</a>
      <router-link :class="linkStyle" to="/privacy">Privacy Policy</router-link>
      <!-- <a :class="linkStyle" target="_blank" href="https://firebasestorage.googleapis.com/v0/b/mpcwebsite2.appspot.com/o/MPC%20Company%20Files%2FVega%20Privacy%20Policy.pdf?alt=media&token=38a2d15c-2a2a-4f12-bdee-44ec43d1de3b">Privacy Policy</a> -->
    </section>
  </div>
</template>
  <script>
import FAQ from "../Dashboard/FAQ.vue";
import Button from "../UI/Button.vue";
import { Icon } from "@iconify/vue";
import { ref } from "vue";
export default {
  components: { FAQ, Button, Icon },
  setup() {
 const headerStyle = ref(" text-base md:text-xl font-medium font-inter mb-4")
 const linkStyle = ref("text-[0.5rem] md:text-xs tracking-wide leading-5 mb-2 font-light")
    return {
        headerStyle,
        linkStyle
    };
  },
};
</script>
