<template>
  <header class="text-primary md:p-4 flex items-center justify-between mx-8">
    <div class="flex items-center space-x-2">
      <img src="@/assets/vega.svg" alt="Company Logo" class="h-8 w-8" />
      <span class="text-base md:text-xl font-medium font-inter">Vega</span>
    </div>
  <NavButtons/>
    <div class="hidden md:flex items-center space-x-4 text-right">
      <a href="https://app.mypocketcounsel.com" target="_blank">
        <Button
        buttonText="Sign In"
        backgroundColor="bg-white"
        textColor="text-neutral-500"
        borderColor="border-neutral-400"
        :clickHandler="handleClick"
      />
      </a>
      <a href="https://app.mypocketcounsel.com" target="_blank">
        <Button buttonText="Try for Free" :clickHandler="handleClick" />
      </a>
     
    </div>
  </header>
  <div class="w-100% border border-zinc-300 border-opacity-60"></div>
</template>
    
    <script>
import { Icon } from "@iconify/vue";
import NavButtons from './NavButtons.vue'
import Button from "../UI/Button.vue";

export default {
  components: { Icon, NavButtons, Button },
  setup() {
    return {};
  },
};
</script>
    
