<template>
  <section class="mx-12 mt-8 ">
    <section :class="sectionClasses" >
      <FeatContent
      class="w-full lg:w-2/5"
        :title="'Instant Contract Creation'"
        :description="'Streamline your contract creation for every team within your organization effortlessly with our instant generation feature. Save valuable time by selecting from our template libaray or onboard your custom templates. Our robust and user-friendly free text editing tool empowers you to make amendments and edits with ease, ensuring flexibility and efficiency at your fingertips.'"
      />
      <div :class="imageContainer">
        <img src="@/assets/agree.svg" alt="" />
      </div>
    </section>
     <section :class="sectionClasses" class="flex flex-col-reverse ">
      <div :class="imageContainer">
        <img src="@/assets/status1.svg" alt="" :class="image1"/>
        <img src="@/assets/status2.svg" alt=""  :class="image2"/>
      </div>
      <FeatContent
        class="w-full lg:w-2/5 "
        :title="'Seamless Contract Management'"
        :description="'Experience seamless contract management with our real-time status tracking feature. Stay on top of your contracts effortlessly as you monitor and manage their statuses in real-time. Our intuitive platform ensures that you have a clear, up-to-the-minute view of all your contracts, enhancing your control and decision-making. Elevate your contract management experience with our powerful tools, empowering you to navigate through every stage with ease.'"
      />
    </section>
     <section :class="sectionClasses" class="md:mt-18 mt-14">
      <FeatContent
        class="w-full lg:w-2/5"
        :title="'Streamlined Tasks Management'"
        :description="'Elevate your task management experience with our real-time oversight of company obligations, ranging from HR filings to contract commitments. Our platform offers a streamlined approach to efficiently manage tasks, ensuring you stay on top of every responsibility. With real-time tracking and intuitive tools, navigate seamlessly through HR processes and contractual obligations.'"
      />
      <div :class="imageContainer">
        <img src="@/assets/task1.svg" alt="" :class="image1"/>
        <img src="@/assets/task2.svg" alt=""  :class="image2"/>
      </div>
    </section>
    <section :class="sectionClasses" class="flex flex-col-reverse md:mt-18 mt-14">
      <div :class="imageContainer">
      
          <img src="@/assets/team1.svg" alt="" :class="image1"/>
          <img src="@/assets/team2.svg" alt="" class="max-[500px]:w-72" :class="image2"/>

      
      </div>
      <FeatContent
      class="w-full md:w-full lg:w-2/5"
        :title="'Empower Every Department'"
        :description="'Seamlessly integrate team members from any department into our platform, Vega, and unlock the power of unified collaboration. Whether it`s HR, Finance, or Marketing, Vega caters to all departments, providing a versatile and intuitive solution. Empower your teams to streamline processes, collaborate effortlessly, and enhance productivity. Bring everyone on board and let Vega be the catalyst for department-wide success.'"
      />
   
    </section>
  </section>
</template>
<script>
import FeatContent from "./FeatContent.vue";
import { ref } from "vue";

export default {
  components: {
    FeatContent,
  },

setup() {
const sectionClasses = ref("  lg:flex lg:flex-row lg:justify-between lg:item-center lg:justify-center");
const imageContainer = ref("w-80% md:block lg:w-1/2 py-8")
const image1 = ref("relative z-2 ")
const image2 = ref("absolute -translate-x-1/2 max-[280px]:hidden max-[450px]:ml-[14rem] max-[375px]:ml-[12rem] max-[500px]:w-64 max-[375px]:w-60 ml-[18rem] lg:ml-[18rem] -translate-y-1/2 mt-[-6rem] z-10")

return {
sectionClasses,
imageContainer,
image1,
image2
    };
}
};
</script>