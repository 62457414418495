<template>
  <router-view/>
</template>

<script>
import { provide } from 'vue'
import store from './components/store'
export default {
  name: 'App',
 setup() {
  provide ('store', store)
 }
}
</script>



