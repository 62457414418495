<template>
  <section>
    <div
      class="flex flex-col items-center md:flex-row justify-center space-y-4 md:space-y-0 space-x-0 md:space-x-12 mx-8 my-4"
    >
      <div class="md:mt-0 self-start">
        <router-link to="/">
          <Button buttonText="Contract" :clickHandler="handleClick" />
        </router-link>
      </div>
      <div class="w-full md:w-[60%] mx-12">
        <h2
          class="my-4 text-center text-zinc-750 text-4xl md:text-5xl leading-tight tracking-wide font-semibold"
        >
          Unlock your business potential with our all-in-one legal platform
        </h2>
        <p class="text-center text-zinc-800 md:leading-7 tracking-wide my-4">
          Create, store, manage and sign your contracts on Vega
        </p>
        <div
          class="flex flex-row items-center my-8 justify-center space-x-4 md:space-y-0 space-x-0 md:space-x-4"
        >
          <a href="https://calendly.com/mypocketcounsel/30min" target="_blank">
            <Button buttonText="Request a Demo" :clickHandler="handleClick" />
          </a>

          <router-link :class="linkStyle" to="/contact-us">
            <Button
              buttonText="Contact Us"
              backgroundColor="bg-white"
              textColor="text-neutral-500"
              borderColor="border-neutral-400"
              :clickHandler="handleClick"
            />
          </router-link>
        </div>
      </div>

      <div class="hidden md:block md:mt-0 self-end">
        <router-link to="/">
          <Button
            buttonText="Tasks"
            borderColor="border-amber-400"
            backgroundColor="bg-amber-400"
            :clickHandler="handleClick"
          />
        </router-link>
      </div>
    </div>
    <div class="hidden md:block mx-auto  w-[85%]">
      <img src="@/assets/Dashboard.svg" alt="" class="w-full" />
    </div>

    <div class="md:hidden mx-8 my-4">
      <!-- Different image for smaller screens -->
      <img src="@/assets/assetssmall.svg" alt="" class="w-full" />
    </div>
  </section>
</template>
<script>
import { Icon } from "@iconify/vue";
import Button from "../UI/Button.vue";

export default {
  components: { Icon, Button },
  setup() {
    return {};
  },
};
</script>